@import 'variables.scss';

#navbar {
  background-color: $brand;
  transition: box-shadow 0.15s ease-in-out;
  &.has-dropshadow {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }
  .navbar-item.has-dropdown.is-active, .navbar-dropdown {
    background-color: $brand;
  }
  .navbar-dropdown {
    background-color: $brand;
    border-top-color: darken($brand, 2);
  }
  .navbar-item {
    &:focus .navbar-link,
    &:hover .navbar-link,
    &:focus,
    &.is-active .navbar-link {
      color: $white;
      background-color: darken($brand, 2);
    }
  }
}