$brand: #8A4D76;
$pink: #FA7C91;
$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;


// Update Bulma's global variables
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $brand;
$link: $pink;

// Update some of Bulma's component variables
$navbar-burger-color: white;
$navbar-dropdown-arrow: white;
$custom-colors: ("brand": ($brand, #FFF));

@import "~bulma/sass/utilities/_all.sass";