
.icon {
  font-weight: normal !important;
  &.is-auto {
    width: 1em;
    height: 1em;
  }
  &.is-small {
    font-size: 1em;
  }
  &.is-medium {
    font-size: 2em;
  }
  &.is-large {
    font-size: 3em;
  }
}