 @font-face {
  font-family: "react-bulma-icons";
  src: url('react-bulma-icons.eot?t=1619425234492'); /* IE9*/
  src: url('react-bulma-icons.eot?t=1619425234492#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("react-bulma-icons.woff2?t=1619425234492") format("woff2"),
  url("react-bulma-icons.woff?t=1619425234492") format("woff"),
  url('react-bulma-icons.ttf?t=1619425234492') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('react-bulma-icons.svg?t=1619425234492#react-bulma-icons') format('svg'); /* iOS 4.1- */
  font-display: swap;
}

[class^="react-bulma-icons-"], [class*=" react-bulma-icons-"] {
  font-family: 'react-bulma-icons' !important;
  font-size: 1em;
  line-height: 1em;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.react-bulma-icons-books:before { content: "\ea01"; }
.react-bulma-icons-cubes:before { content: "\ea02"; }
.react-bulma-icons-download:before { content: "\ea03"; }
.react-bulma-icons-earth:before { content: "\ea04"; }
.react-bulma-icons-github-alt:before { content: "\ea05"; }
.react-bulma-icons-github:before { content: "\ea06"; }
.react-bulma-icons-home:before { content: "\ea07"; }
.react-bulma-icons-lock:before { content: "\ea08"; }
.react-bulma-icons-logo:before { content: "\ea09"; }
.react-bulma-icons-modules:before { content: "\ea0a"; }
.react-bulma-icons-quill:before { content: "\ea0b"; }
.react-bulma-icons-react:before { content: "\ea0c"; }
.react-bulma-icons-responsive-design:before { content: "\ea0d"; }
.react-bulma-icons-responsive:before { content: "\ea0e"; }
.react-bulma-icons-rocket:before { content: "\ea0f"; }
.react-bulma-icons-stack:before { content: "\ea10"; }
.react-bulma-icons-star-empty:before { content: "\ea11"; }
.react-bulma-icons-star-full:before { content: "\ea12"; }
.react-bulma-icons-star-half:before { content: "\ea13"; }
.react-bulma-icons-storybook:before { content: "\ea14"; }
.react-bulma-icons-typescript:before { content: "\ea15"; }
