@charset "utf-8";

// Set your brand colors
@import 'variables.scss';

html, body {
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

// Import only what you need from Bulma
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/helpers/_all.sass";
@import "~bulma/sass/elements/button.sass";
@import "~bulma/sass/elements/box.sass";
@import "~bulma/sass/layout/footer.sass";
@import "~bulma/sass/elements/container.sass";
@import "~bulma/sass/elements/image.sass";
@import "~bulma/sass/elements/title.sass";
@import "~bulma/sass/elements/icon.sass";
@import "~bulma/sass/elements/other.sass";
@import "~bulma/sass/grid/columns.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/message.sass";
@import "~bulma/sass/components/navbar.sass";
@import "~bulma/sass/components/level.sass";
@import "~bulma/sass/components/card.sass";
@import "~bulma/sass/layout/hero.sass";
@import "~bulma/sass/layout/section.sass";